// Insert your styling here.
.box-shadow (@string) {
	-webkit-box-shadow: @string;
	-moz-box-shadow:    @string;
	box-shadow:         @string;
}
.border-radius (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}
.not-front #page-header {
  padding-bottom: 60px;
}

h3 {
	font-weight: normal;
}

.page-header {
  .container();
	padding: 0;
  font-style:italic;
  border-bottom: none;
}

.block-title {
  text-align: center;
	font-size: 44px;
	font-style: italic;
  font-weight: bold;
  color: #171919;
}

.no-padding {
	padding: 0;
}

@media (max-width:@screen-xs-max){
	.no-xs-padding {
		padding: 0;
	}
}

@media (max-width:@screen-sm-max){
	.no-sm-padding {
		padding: 0;
	}
}

#block-system-main {
  .container();
	padding: 0;
}
.front {
  #block-system-main {
    .container();
    padding-top: 20px;
    padding-bottom: 100px;
    min-height: 40vh;
    .node.node-pool-permanent-page {
      &:before {
        content: "";
        display:block;
        position: relative;
        height: 18px;
        width: 18px;
        margin: 30px auto;
        background-color: @brand-secondary;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .field.field-name-body {
      max-width: 700px;
      margin: 0 auto;
      text-align: center;
    }
  }

}
.page-node #block-system-main {
	padding-bottom: 60px;
}


@media (min-width: @screen-sm-min) {
  .col-sm-offset-15 {
    margin-left: 12.5%;
  }
}

.view.view-services {
	margin-top: 70px;
  > .view-content {
   .col-sm-1 {
     width: 14.2857143%;
   }
   .col {
      text-align: center;
      &:hover {
				.views-field-nothing,
        .field.field-name-node-link {
           visibility: visible;
           opacity: 1;
        }
				.views-field.views-field-field-teaser-image a,
        .field.field-name-field-teaser-image a {
          border-color: @brand-secondary;
          img {
            opacity: 0.4;
          }
        }
      }
      &.active0 {
        .field.field-name-field-teaser-image a {
          border-color: @brand-secondary;
          img {
            opacity: 0.4;
          }
        }
      }
      .group-image-wrapper {
        position:relative;
        text-align: center;
        -webkit-transform-style: preserve-3d;
          -moz-transform-style: preserve-3d;
          transform-style: preserve-3d;
      }
			.views-field-nothing,
      .field.field-name-node-link {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
				color: #fff;
				font-weight: bold;
				text-transform: uppercase;
        a {
          text-decoration: none;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
			.views-field.views-field-field-teaser-image {
				position: relative;
			}
			.views-field.views-field-field-teaser-image img {
				height: 160px;
				width: 160px;
			}
			.views-field.views-field-field-teaser-image a,
      .field.field-name-field-teaser-image a {
        display:inline-block;
        border-radius: 50%;
        border: 10px solid lighten(@brand-primary,6.5%);
        background-color: rgba(0,0,0,1);
        -webkit-transition: border-color 0.5s ease-in-out;
        -moz-transition: border-color 0.5s ease-in-out;
        -o-transition: border-color 0.5s ease-in-out;
        transition: all border-color 0.5s ease-in-out;
        img {
          border-radius: 50%;
          opacity: 1;
          -webkit-transition: opacity 0.5s ease-in-out;
          -moz-transition: opacity 0.5s ease-in-out;
          -o-transition: opacity 0.5s ease-in-out;
          transition: opacity 0.5s ease-in-out;
        }
      }

      .views-field.views-field-title-field {
        font-style: italic;
        margin-top: 15px;
        a {
          text-decoration: none;
					color: #0c0c0c;
        }
      }
    }
  }
}

#block-views-services-block {
  padding-top: 80px;
  padding-bottom: 80px;
  .block-title {

  }
  .view.view-services {
    > .view-content {
      .col {
        margin-bottom: 50px;

      }
    }
  }
}
#block-views-services-block-1 {
  position:relative;
  top:-60px;
  margin-bottom: -60px;
  .block-title {
    .container();
    text-transform: uppercase;
    font-weight: bold;
    position:relative;
    text-align:left;
    color: @brand-primary;
    z-index: 5;
    &:after {
      content: none;
    }
    @media (min-width: @screen-md-min){
      top: -80px
    }
  }
  .view.view-services {
    background-color: @brand-primary;
    > .view-content {
      .container();
      .col {
        &.active0 {
          position:relative;
          &:after {
            content:"";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 16px 25px 0 25px;
            border-color: @brand-primary transparent transparent transparent;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
        .node.node-service {
          position:relative;
          top: -60px;
          z-index: 5;
        }
        a {
          color: #fff;
        }
      }
    }
  }
}

/* Flexslider */

.flexslider {
  margin: 0;
  border: 0 none;
  .border-radius(0);
  .box-shadow(0);
}

/* Employees */
#block-views-poolemployees-block-1 {
  padding-top: 80px;
  padding-bottom: 80px;
	.view-poolemployees {
		margin-top: 70px;
	}
  .node-pool-employee {
    text-align: center;
    max-width: 270px;
    height: 430px;
    background-color: #fff;
    margin: 0 auto 30px;
    overflow: hidden;
    .field.field-name-field-pool-employee-image {
      overflow: hidden;
      img {
      -webkit-transition: all 0.5s ease; /* Safari and Chrome */
        -moz-transition: all 0.5s ease; /* Firefox */
        -ms-transition: all 0.5s ease; /* IE 9 */
        -o-transition: all 0.5s ease; /* Opera */
        transition: all 0.5s ease;
      }
    }
    .field.field-name-body {
      font-style:italic;
    }
    &:hover {
      .field.field-name-field-pool-employee-image img {
        -webkit-transform:scale(1.1); /* Safari and Chrome */
        -moz-transform:scale(1.1); /* Firefox */
        -ms-transform:scale(1.1); /* IE 9 */
        -o-transform:scale(1.1); /* Opera */
        transform:scale(1.1);
      }
    }
  }
	.ribbon {
		text-transform: uppercase;
		margin-top: 25px;
		font-weight: bold;
		&:after {
			content: "";
			display: block;
			width: 70px;
			height: 1px;
			background-color: #d5d5d5;
			margin: 15px auto;
		}
	}

	/*
  .ribbon {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    color: #fff;
    background: @brand-secondary;
    position: relative;
    margin-top: -15px;
    margin-bottom: 15px;
    padding: 10px 0px;
    line-height: 1;
    height: 30px;
    letter-spacing: 3px;
    font-size: 75%;
    max-width: 220px;
    white-space: nowrap;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 10px 15px 0;
      border-color: transparent @brand-secondary transparent transparent;
      display: inline-block;
      position: absolute;
      top: 0;
      left: -10px;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 15px 10px;
      border-color: transparent transparent transparent @brand-secondary;
      display: inline-block;
      position: absolute;
      top: 0;
      right:-10px;
    }
  }*/
}

/* Pricelist */

.views-field-field-service-price {
	margin-top: 30px;
}

#block-views-pricelist-block {
  padding-top: 80px;
  padding-bottom: 80px;
  .view.view-pricelist {
    .col {
      padding-top: 30px;
      padding-bottom: 30px;
      height: 215px;
      @media (max-width: @screen-sm-min) {
        border-bottom: 1px solid #f0f0f0;
        &:last-child {
          border-bottom: none;
        }
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
        &:nth-of-type(odd){
          border-right: 1px solid #f0f0f0;
        }
        border-bottom: 1px solid #f0f0f0;
        &:nth-last-child(-n+2) {
          border-bottom: none;
        }
      }
      @media (min-width: @screen-md-min) {
        &:nth-of-type(-n+3) {
          border-bottom: 1px solid #f0f0f0;
        }
        &:nth-of-type(2),
        &:nth-of-type(5){
          border-left: 1px solid #f0f0f0;
          border-right: 1px solid #f0f0f0;
        }
      }
    }
    .node-product {
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
      height: 150px;
    }
    .field.field-name-title-field {
      color: @brand-secondary;
      text-align: center;
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 30px;
      a {
        color: @brand-secondary;
        text-decoration: none;
      }
    }
    .entity  {
      white-space:nowrap;
      font-size: 14px;
      line-height: 1.8;
      > span {
        float:left;
        overflow:hidden;
        &:first-child {
          width: 85%;
          white-space:nowrap;
          &:after {
            content: " ..................................................................................";
          }
        }
        &:only-child {
          &:after {
            content: "";
          }
        }
      }
    }
  }
}
.ds-1col.node-product {
    max-width: 230px;
    //margin-left: auto;
    //margin-right: auto;
    height: 150px;
  .field.field-name-title-field {
    color: @brand-secondary;
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .entity  {
    white-space:nowrap;
    font-size: 14px;
    line-height: 1.8;
    > span {
      float:left;
      overflow:hidden;
      &:first-child {
        width: 85%;
        white-space:nowrap;
        &:after {
          content: " ..................................................................................";
        }
      }
      &:only-child {
        &:after {
          content: "";
        }
      }
    }
  }
}
/* Contact */
#block-bean-contact-block {
	background: url('../images/contact_form_bg.jpg') no-repeat center;
	.cover();
	color: #fff;
	padding-top: 60px;
	padding-bottom: 70px;
	.block-title {
		color: #fff;
	}
	.field-name-field-text {
		padding-left: 15px;
		padding-right: 15px;
	}
}

#contact-form-entityform-edit-form {
	::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #fff;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	   color:    #fff;
	   opacity:  1;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
	   color:    #fff;
	   opacity:  1;
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
	   color:    #fff;
	}
	::-ms-input-placeholder { /* Microsoft Edge */
	   color:    #fff;
	}
	background-color: rgba(14,14,14,.3);
	padding: 15px;
	max-width: 730px;
	margin: 0 auto;
	.control-label {
		position:absolute;
		text-indent: -9999px;
	}
	.form-group {
		margin-bottom: 0;
	}
	.form-control {
		background-color: #0e0e0e;
		border-color: #0e0e0e;
		margin-bottom: 15px;
		color: #fff;
		.input-lg;
		font-style: italic;
	}
	.form-text {
		height: 56px;
	}
	.form-textarea {
		min-height: 112px;
	}
	.field-name-field-name {
		.make-md-column(6);
		padding: 0;
		@media (min-width: @screen-md-min) {
			padding-left: 0;
			padding-right: 7.5px;
		}
	}
	.field-name-field-phone {
		.make-md-column(6);
		padding:0;
		@media (min-width: @screen-md-min) {
			padding-left: 7.5px;
			padding-right: 0;
		}
	}
	.form-submit {
		.btn-lg;
		.btn-block;
		height: 56px;
		border-radius: 0;
		background-color:@brand-primary;
		border-color:@brand-primary;
		color: #fff;
		text-transform: uppercase;
	}
	.grippie {
		display: none;
	}
}

/* Reference */

#block-bean-reference {
  background-color: @brand-secondary; //#dbdbdb;
	color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  .entity {
    .container;
    h4 {
      margin-top: 0;
      font-weight: bold;
      font-style: italic;
    }
  }
}

/* Gift card */
#block-bean-lahjakortti {
  background-color: @brand-primary; //#dbdbdb;
	color: #fff;
	padding-top: 50px;
	padding-bottom: 60px;
  .entity {
    .container;
    > .row {
      @media (max-width: @screen-sm-min) {
        padding-top: 30px;
        padding-bottom: 15px;
      }
      > div {
        text-align:center;
        padding-bottom: 15px;
        @media (min-width: @screen-sm-min) {
          text-align: left;
          height: 125px;
          -webkit-transform-style: preserve-3d;
          -moz-transform-style: preserve-3d;
          transform-style: preserve-3d;
          > .field {
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.flexslider .slides > li a img{visibility:visible !important; opacity:1 !important;filter:alpha(opacity=100) !important;}

/* Facebook feed  */
.carousel li {
  margin-right: 0;
  }
#block-views-facebook-feed-block {

	.flexslider .flex-next {opacity: 0.8 !important; right: 5px !important;} .flexslider .flex-prev {opacity: 0.8 !important; left: 5px !important;}
	.flex-direction-nav a {
	  text-decoration: none;
	  display: block;
	  width: 40px;
	  height: 40px;
	  margin: -20px 0 0;
	  position: absolute;
		top: 17%;
		@media (min-width: @screen-sm-min){
	  	top: 50%;
		}
	  z-index: 10;
	  overflow: hidden;
	  opacity: 1 !important;
	  cursor: pointer;
	  color: rgba(0, 0, 0, 0.8);
	  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
	  -webkit-transition: none !important;
	  -moz-transition: none !important;
	  -ms-transition: none !important;
	  -o-transition: none !important;
	  transition: none !important;
	}

	.flexslider .flex-direction-nav a,
	.flexslider .flex-direction-nav a {
	  display: block !important;
	}
	.flexslider:hover .flex-direction-nav a,
	.flexslider:hover .flex-direction-nav a {
	  display: block;
	}




  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 698px;
	//background: @brand-primary url('../images/facebook.png') no-repeat;
	//background-position: right 5% bottom -70%;
	.view.view-facebook-feed {
    margin-top: 70px;
		.views-row {
			a {
				color: #fff;
				text-decoration: none;
			}
			&:hover {
				color: #026664;
				a {
					color: #026664;
				}
			}
		}
    .field.field-name-field-text {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      line-height: 1.6;
    }
		.field-name-post-date {
			color: #b2b2b2;
		}
		.field-name-body {
			font-size: 17px;
		}
		.field-name-field-link {
			font-size: 17px;
			font-weight: bold;
			a:before {
				content:">";
			}
		}
		.view-content .flexslider {
	  	  .flex-viewport {
	  		  /*height: 356px;*/
	  		  max-width: 1140px;
	  		  margin: 0 auto;
	      }
		  .flex-direction-nav a {
			  &:before {
				  font-size: 40px;
				  font-weight: bold;
			  }
		  }
	 	  .slides > li {
	 	   > div {
			   background: #fff;
	 		   /*padding-left: 15px;
	 		   padding-right: 15px;*/
			   margin-right: 15px;
			   margin-left: 15px;
			   .field.field-name-field-image {
				   .field-item {
					   text-align: center;
					   /*height: 220px;
					   line-height: 215px;*/
					   img {
						   max-width: 100%;
						   width: auto;
						   height: auto;
						   margin: auto;
						   max-height: 215px;
						   line-height: 0;
						   display: inline-block;
						   vertical-align: middle;
					   }
				   }
			   }
			   .group-fb-link {
				   text-decoration: none;
				   color: #969595;
				   display: block;
			   }
			   .field.field-name-post-date {
           margin-top: 15px;
					 margin-bottom: 15px;
			   }
			   .field.field-name-body {
					 margin-bottom: 15px;
			   }
         .field.field-name-field-story {
					 font-size: 17px;
         }
			   .group-wrapper.field-group-div {
           white-space:nowrap;
				   background: #f5f5f5;
				   color: #969595;
				   padding: 10px 15px;
				   font-size: 12px;
           position:relative;
				   .field.field-name-field-comments-count {
					   display: inline-block;
					   margin-right: 10px;
					   background: #fff;
					   padding: 10px 20px;
					   border-radius: 20px;
					   .field-item:before {
			  		   font-family: FontAwesome;
			  			  content: @fa-var-comment;
						    margin-right: 5px;
						    color: #dadada;
					    }
				   }
				   .field.field-name-field-likes-count {
				   	  display: inline-block;
					    background: #fff;
					    padding: 10px 20px;
					    border-radius: 20px;
					    .field-item:before {
			  			  font-family: FontAwesome;
			  			  content: @fa-var-heart;
						    margin-right: 5px;
						    color: #dadada;
					    }
				   }
				   .field.field-name-field-link {
             &:hover {
               cursor:pointer;
             }
				     display: inline-block;
					   /*float: right;*/
             position:absolute;
             top: 10px;
             right: 15px;
					   background: #fff;
					   padding: 10px 20px;
					   border-radius: 20px;
						 font-size: 17px;
						 font-weight: bold;
					   .field-item:before {
			  			  font-family: FontAwesome;
			  			  content: @fa-var-share;
						    margin-right: 5px;
						    color: #dadada;
					   }
				   }
			   }
	 	   }
     }
	 	}
	}
}

.flex-direction-nav a {
  line-height: 48px;
  height: 48px;
}

/* Services */
.page-palvelut .page-header {
	display: none;
}

.view-services-collapse {
	overflow: auto;
	margin-bottom: 100px;
  .panel, .panel * {
    -webkit-box-shadow: none;
    -moz-box-shadow:none;
    box-shadow: none;
    @media (min-width: @screen-md-min){
      border: 0 none;
    }
  }
}

.panel-heading {
	cursor: pointer;
}

.panel-body {
  padding: 30px 20px;
}

.panel-primary {
  .panel-heading {
    a {
      &:before {
        content: "\00BB";
        margin-right: 10px;
        color: #fff;
      }
      &:hover {
        color: inherit;
      }
    }
    .fa-angle-up {
      display: block;
    }
    .fa-angle-down {
      display: none;
    }
    &.collapsed {
      a {
        &:before {
          color: @brand-primary;
        }
      }
      background-color: #fff;
      color: @gray-dark;
      .fa-angle-up {
        display: none;
      }
      .fa-angle-down {
        display: block;
      }
    }
  }
}

.view-services-collapse {
  @media (min-width:@screen-md-min) {
    .panel-primary > .panel-heading + .panel-collapse > .panel-body {
      padding-top: 0;
			border: 0 none;
    }
		.panel-body {
			h1,h2,h3,h4,h5,h6 {
				&:first-child {
					margin-top: 0;
				}
			}
		}
  }
}


.tabs-left > li {
  float: none !important;
}
.nav-tabs {
  &.tabs-left {
    > li {
      margin-top: 5px;
      > a {
        border: 1px solid #eeeeee;
        border-radius: 0;
        background-color: @brand-primary;
        color: #fff;
				font-size: 18px;
        &:before {
					content: "\00BB";
          color: #fff;
					margin-right: 5px;
					margin-left: 5px;
        }
        &.collapsed {
          background-color: #fff;
          color: @gray-dark;
          &:before {
            color: @text-color;
						margin-left: 0px;
          }
        }
      }
    }
  }
}
@media (min-width:@screen-md-min){
	.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
	}
}
