// Insert your styling here.

//dropdown
#navbar {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.75);
	ul.nav li.dropdown:hover ul.dropdown-menu {
		display: block;
	}
	ul.nav li.dropdown ul.dropdown-menu {
		margin-top: 0;
		@media (max-width: @grid-float-breakpoint){
			position:relative;
			width: 100%;
			margin-bottom: 15px;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
      /*background-color: @brand-primary;*/
		}
    li a {
      font-style:italic;
    }
	}
}
.navtop {
  border-top: 10px solid @brand-primary;
  border-bottom: 1px solid #e7e7e7;
}
.nav-container {
  @media (min-width: @grid-float-breakpoint){
    .container();
  }
}
.region.region-navigation {
 @media (min-width: @screen-lg-min){
   .container();
 }
}
.navbar-brand {
  font-family: Papyrus, fantasy;
  font-style: italic;
  font-size: 36px;
  line-height: 1;
}
.region-navigation {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.nav-container.affix {
  width: 100%;
  z-index: 100;
}

.navbar-nav {
  > li > a {
    padding: 18px 30px;
    font-size: 16px;
    font-weight: bold;
  }
}
@media (min-width: @grid-float-breakpoint){
  .navbar-nav {
    //.pull-right();
    white-space: nowrap;
    > li {
      float:none;
      display: inline-block;
    }
  }

  #navbar {
   /* top: 0px;
    z-index: 10;
    > div {
      border-top: 1px solid #bbc3c6;
      border-bottom: 1px solid #bbc3c6;
    }*/
  }
  /*.main-container {
    position:relative;
    top: -88px;
    margin-bottom: -88px;

  }*/
  .navbar-header {
    position: absolute;
    top: 30px;
    /*background-color: @brand-primary;
    min-height: 66px;
    position:relative;
    @media (min-width: @grid-float-breakpoint){
      &:after {
        content:"";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 33px 0 33px 22px;
        border-color: transparent transparent transparent @brand-primary;
        position: absolute;
        left: 100%;
      }
    }*/
  }
}

@media (min-width: @grid-float-breakpoint) {
  .navbar-brand {
    .pull-right;
  }
  .navbar-default {
    /*background-color: transparent;*/
    .navbar-nav {
     /* > li > a {
        color: @brand-primary;
        &.active-trail.dropdown-toggle {
          color: #fff; //@brand-secondary;
          background-color: @brand-primary;
        }
        &:hover,
        &:focus {
          color: #fff; //@brand-secondary;
          background-color: @brand-primary;
        }
      }
      > .active > a {
        &,
        &:hover,
        &:focus {
          color: #fff; //@brand-secondary;
          background-color: @brand-primary;
        }
        }*/
    }
  }
}

.navbar-default {
  .navbar-nav {
     > li {
      a {
         text-align: center;
      }
      > a {
        text-transform: uppercase;
      }
    }
  }
}

.navbar .logo {
  padding: 15px;
  margin: 0;
  img {
    max-width: 200px;
    @media (min-width: @grid-float-breakpoint){
      max-width: 100%;
    }
    height: auto;
  }
}

/*
.front .navbar-default {
  .navbar-nav {
    > .active:nth-of-type(n+2) > a {
      color: @brand-primary;
      background-color: transparent;
      &:hover,
      &:focus {
        background-color: @brand-primary;
        color: @brand-secondary;
      }
    }
  }
}*/
.view-frontpage-slideshow {
  min-height: 300px;
}
/* Header image */
.not-front .header-bg {
  //padding-bottom: 17.317846%;
  min-height: 300px;
  max-height: 300px;
  @media (min-width: @screen-sm-min) {
    background-position: center !important;
  }
  .flex-caption {
    display: none;
  }
}
.flexslider {
  overflow: hidden;
}
.header-bg {
  /*@media (max-width: @screen-sm-min) {
    background-position: center right !important;
  }*/
  .cover();
  width: 100%;
  /*padding-bottom: 45.3%;*/
  min-height: 100vh;
  @media (min-width: @grid-float-breakpoint){
    min-height: 100vh;
  }
  .flex-caption {
    color: #fff;
    position: relative;
    top: 30vh;
    text-align: center;
    font-style: italic;
    > div {
      max-width: 640px;
      margin: 0 auto;
    }
    h1 {
      font-weight: bold;
      @media (min-width: @screen-sm-min) {
        font-size: 70px;
      }
    }
    h2, h3 {
      font-size: 25px;
    }
    p {
      font-size: 16px;
    }
    .btn-lg {
      padding: 16px 50px;
      margin-top: 30px;
    }
  }
  position: relative;
  transform: translate3d(0px, 0px, 0px);

  /*
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  .flex-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    @media (min-width: @screen-md-min) {
      left: 30%;
      -webkit-transform: translate(-50%,-30%);
      -moz-transform: translate(-50%,-30%);
      transform: translate(-50%,-30%);
    }
      p {
        font-size: 5px;
        line-height: 1;
        font-weight: bold;
        color: @brand-primary;
        white-space:nowrap;
        @media (min-width: @screen-xs-min) {
          font-size: 10px;
        }
        @media (min-width: @screen-md-min) {
          font-size: 15px;
        }
        em {
          font-size: 6em;
          font-style:normal;
        }
        .chevron-right{
          &:nth-of-type(-n+2){
            width: 0px;
          }
          &:first-of-type {
            margin-left: 2.5em;
          }
        }

      }
  }*/
}

.chevron-right {
  color: #fff;
  background-color: @brand-primary;
  display: inline-block;
  position: relative;
  margin-right: 0.2em;
  margin-left: 0.3em;
  font-size: 6em;
  line-height: 1;

  &:before {
    content: "";
    display: inline-block;
    border-left: 0.2em solid transparent;
    border-top: 0.5em solid @brand-primary;
    border-right: 0em solid @brand-primary;
    border-bottom: 0.5em solid @brand-primary;
    position: absolute;
    z-index: -1;
    top: 0;
    left: -0.2em;
  }
  &:after {
    content: "";
    display: inline-block;
    border-left: 0.2em solid @brand-primary;
    border-top: 0.5em solid transparent;
    border-right: 0em solid transparent;
    border-bottom: 0.5em solid transparent;
    position: absolute;
    z-index: -1;
    top: 0;
    right: -0.2em;
  }
}

.navbar-toggle {
  padding: 17px 0px;
}

#block-bean-osoite {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 180px;
  white-space: nowrap;
  zoom:.85;
  @media (min-width: @screen-sm-min){
    zoom:1;
  }
  .content {
    font-size: 17px;
    font-weight: bold;
    height: 60px;
    overflow: hidden;
    &:before {
      content: "";
      display: block;
      width: 33px;
      height: 43px;
      background: url('../images/location.png') no-repeat;
      float: left;
      margin-right: 15px;
    }
  }
}
#block-bean-puhelin {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 180px;
  white-space: nowrap;
  zoom:.85;
  @media (min-width: @screen-sm-min){
    zoom:1;
  }
  .content {
    height: 60px;
    overflow-y: hidden;
    font-size: 17px;
    font-weight: bold;
    &:before {
      content: "";
      display: block;
      width: 33px;
      height: 43px;
      background: url('../images/phone.png') no-repeat;
      float: left;
      margin-right: 15px;
    }
  }
}
