// Insert your styling here.
.footer {
  font-size: 18px;
  padding-bottom: 0;
  background-color: #fff; //#212121;
  color: @text-color;
  margin-top: 0;
  .block-title {
    text-align: left;
    color: @text-color;
    font-size: 18px;
    font-style: normal;
    text-transform: uppercase;
  }
  a {
    color: @text-color;
    &:hover {
      color: @brand-primary;
    }
  }
  .menu {
    text-align: right;
    li {
      display: inline;
      a {
        margin-right: 10px;
        color: #5f5f5f;
        text-decoration: none;
        text-transform: uppercase;
        &:hover, &:focus {
          color: #fff;
        }
      }
      &.active a {

      }
      /*&.active:nth-of-type(n+2) > a {
        color: #434343;
      }*/
    }
  }
  #block-block-1 {
    border-top: 1px solid #dfdfdf;
    margin-top: 20px;
    padding: 20px 0;
    text-align: center;
    color: #878787;
    font-size: 16px;
    font-style: italic;
  }
}
